import { useState } from "react";
import { useDispatch } from "react-redux";
import { applyAnswer } from "../../stateMachine";

const GameForm = ({ word, onAttempt }) => {
  const dispatch = useDispatch();
  const [answer, setAnswer] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(
      applyAnswer({
        word,
        answer
      })
    );

    onAttempt(answer, word.word === answer);

    setAnswer("");
  };

  const handleInput = (e) => {
    if(e.nativeEvent.keyCode !== 13) {
      if (answer.length >  e.target.value.length) {
        return e.preventDefault()
      }

      setAnswer(e.target.value);
    }
  };

  return (
    <div className="GamePage__form">
      <form onSubmit={handleSubmit}>
        <input onChange={handleInput} onKeyPress={handleInput} value={answer} autoComplete="off" className="GamePage__form-input" autofocus="autofocus"/>
      </form>
    </div>
  );
};

export default GameForm;
