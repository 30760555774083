import { setState } from "../stateMachine";

import logo from "./../../../../images/logos/bell_logo.svg";
import home_image from "../../../../images/pages/home.png";
import I18n from "../../../ui/I18n";
import { useDispatch, useSelector } from "react-redux";

const Home = () => {
  const participation = useSelector(
    (state) => state.stateMachine.participation
  );
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(setState("signup_initials"));
  };

  return (
    <div className="HomePage">
      <div className="HomePage__top">
        <img className="HomePage__logo" src={logo} alt="Logo Bell" />
        <div className="HomePage__content">
          <I18n t="client.home.content" />
        </div>
        <div className="HomePage__buttons">
          <button
            className="button button--long"
            onClick={handleClick} disabled={!participation}>
            <I18n t="client.home.button" />
          </button>
        </div>
      </div>
      <div className="HomePage__bottom">
        <img
          className="HomePage__decoration"
          src={home_image}
          alt="Logo Bell"
        />
      </div>
    </div>
  );
};

export default Home;
