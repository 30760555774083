import { useDispatch, useSelector } from "react-redux";
import { sendMessage } from "../stateMachine";
import Timer from "./Timer";
import I18n from "../../../ui/I18n";

import "./actions.scss";
import { useState } from "react";
import Modal from "../../../ui/Modal/Modal";
import LogoBell from "../../../../images/logos/bell_logo.svg";

const DEV_MODE = process.env.NODE_ENV !== "production";

const Actions = () => {
  const step = useSelector((state) => state.stateMachine.step);
  const {
    display: displayCountdown
  } = useSelector((state) => state.stateMachine.countdown);
  const [showResetConfirm, setShowResetConfirm] = useState(false);
  const dispatch = useDispatch();

  const BUTTONS = [
    {
      action: "startExperience",
      allowedState: ["home"],
    },
    {
      action: "switchGame",
      allowedState: ["home", "final"],
    },
    {
      action: "manualStart",
      allowedState: ["signup"],
    },
    {
      action: "reset",
      onClick: () => {
        setShowResetConfirm(true);
      },

      allowedState: [],
      classNames: "button--red",
    },
  ];

  const emitAction = (action) => {
    dispatch(
      sendMessage({
        type: action,
        message: {},
      })
    );
  };

  const renderButton = ({ onClick, action, allowedState, classNames = "" }) => {
    if (allowedState.length > 0 &&  !allowedState.includes(step)) {
      return;
    }

    const onEvent = () => {
      if (onClick) {
        onClick();
      } else {
        emitAction(action);
      }
    };
    return (
      <button
        key={action}
        className={`button button--long state ${classNames}`}
        onClick={onEvent}
        data-action={action}
        disabled={allowedState.length > 0 && !allowedState.includes(step)}
      >
        <I18n l="en-CA" t={`admin.action_buttons.${action}`} />
      </button>
    );
  };

  const renderResetConfirm = () => {
    const buttons = [
      {
        label: "Cancel",
        onClick: () => {
          setShowResetConfirm(false);
        },
      },
      {
        label: "Reset",
        className: "button--red",
        onClick: () => {
          dispatch(
            sendMessage({
              type: "reset",
              message: {},
            })
          );

          setShowResetConfirm(false);
        },
      },
    ];
    return (
      <Modal buttons={buttons} className="Admin__confirm">
        <h3>Are you sure?</h3>
      </Modal>
    );
  };

  return (
    <div className="actions">
      <img className="actions__logo" src={LogoBell} />
      <div className="actions__current"><strong>Current Step:</strong> {step}</div>
      <br/>
      {displayCountdown && <Timer />}
      {showResetConfirm && renderResetConfirm()}

      {BUTTONS.map(renderButton)}

      {DEV_MODE && [
        <hr/>,
        renderButton({action:'toggleProgress', allowedState:[]}),
        renderButton({action:'reset', allowedState:[]})
      ]}
    </div>
  );
};

export default Actions;
