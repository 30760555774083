import React from "react";
import I18n from "../../../ui/I18n";
import decoration_image from "../../../../images/deco_up.png";
import decoration_bottom from "../../../../images/sorry_dec_bottom.png";

const Sorry = () => {
  return (
    <>
      <img
        className="SignupInitialsPage__decoration-image"
        src={decoration_image}
        alt=""
      />
      <div className="SorryPage">
        <div className="SorryPage__title">
          <I18n t="client.sorry.title" />
          <br></br>
          <I18n t="client.sorry.sorry_text" />
        </div>

        <div className="SorryPage__sub-title">
          <I18n t="client.sorry.text" />
        </div>
        <div className="SorryPage__bottom">
          <img
            className="SorryPage__decoration"
            src={decoration_bottom}
            alt=""
          />
        </div>
      </div>
    </>
  );
};

export default Sorry;
