import { createSlice } from "@reduxjs/toolkit";
import { offlineState } from "./offline";

export const initialState = {
  step: "home",
  lang: "fr-CA",
  secondsLeft: 5,
  participation: false,
  sessionID: null,
  lastMessageAt: null,
  connected: false,
  hash: null,
  focused: true,
  startedAt: null,
  initials: null,
  profilePicture: null,
  words: [],
  score: null,
  correct: false,
  countdown: {
    leftTime: 5,
    total: 5
  },
  isSignupCompleted: false,
  winner: false,
  currentWordIndex: 0,
  finised: false,
  attempts: []
};

window._attachedEvents = false;

const RECONNECT_CASES = [
  "io client disconnect",
  "forced close",
  "focus",
  "transport close",
  "transport error",
  "Error: websocket error"
];

const PRIORITY_STATES = [
  "home",
  "signup_initials",
  "signup_image",
  "signup_avatar",
  "signup_selfie",
  "waiting",
  "countdown",
  "lightshow",
  "blackscreen",
  "winnerslight",
  "winner",
  "loser",
  "sorry"
];

const shouldUpdateState = (currentStep, newStep) => {
  return (
    PRIORITY_STATES.indexOf(currentStep) <
    PRIORITY_STATES.indexOf(newStep)
  );
};

const shouldReconnect = (state, payloadMessage) => {
  const { hash, sessionID, focused } = state;

  let lightShowReconnect = true;

  if (state.step === "lightshow") {
    const elapsedTime = new Date() - new Date(state.lastMessageAt);

    if (elapsedTime > 14000) {
      lightShowReconnect = false;
    }
  }

  return (
    hash &&
    focused &&
    sessionID &&
    lightShowReconnect &&
    RECONNECT_CASES.includes(payloadMessage)
  );
};

const randomize = (words) => {
  for (let index = words.length - 1; index > 0; index--) {
    const destIndex = Math.floor(Math.random() * (index + 1));

    // Swap positions
    [words[destIndex], words[index]] = [
      words[index],
      words[destIndex]
    ];
  }

  return words;
};

export const stateMachine = createSlice({
  name: "stateMachine",
  initialState,
  reducers: {
    onMessage: (state, { payload: { type, param, timestamp } }) => {
      console.log(
        type,
        JSON.stringify({
          type: "stateMachine/onMessage",
          payload: { type, param }
        })
      );

      switch (type) {
        case "state":
          if (state.step !== param) {
            state.lastMessageAt = timestamp;
          }

          // if (shouldUpdateState(state.step, param)) {
          state.step = param;
          // }
          break;

        case "countdown":
          state.countdown = {
            ...state.countdown,
            ...param
          };
          break;

        case "words":
          state.words = randomize(param);
          break;

        case "score":
          state.score = param.score;
          state.roundScore = param.roundScore;
          break;

        case "final":
          state.winner = param.winner;
          state.score = param.score;
          state.step = "final";
          break;

        case "start":
          state.participation = true;
          break;

        // case "reset":
        //   window.localStorage.clear();
        //   window.location.reload();
        //   break;

        default:
          return;
      }
    },
    setSession: (
      state,
      { payload: { hash, initials, sessionID } }
    ) => {
      window.localStorage.setItem(`${hash}-sessionID`, sessionID);

      window._WSConnection.auth = { sessionID };
      window._WSConnection.userID = sessionID;
      window._WSActions.subscribe();

      state.sessionID = sessionID;
      state.initials = initials;
      state.step = "signup_avatar";
    },
    setProfilePicture: (state, { payload: { profilePicture } }) => {
      state.profilePicture = profilePicture;
      state.isSignupCompleted = true;
      state.step = "instructions";
    },
    setStarted: (state, { payload: { startedAt } }) => {
      state.startedAt = startedAt;
    },
    onDisconnection: (state, { payload }) => {
      state.connected = false;

      // Reconnection rules
      // if (shouldReconnect(state, payload.message)) {
      //   setTimeout(() => {
      //     window._WSActions.subscribe();
      //   }, 300);
      // }
    },
    onConnection: (state, { payload }) => {
      state.connected = true;
    },
    applyAnswer: (state, { payload }) => {
      window._WSActions.sendMessage("applyAnswer", payload);
    },
    onFocus: (state, { payload }) => {
      state.focused = true;

      // Reconnection rules
      if (shouldReconnect(state, "focus")) {
        window._WSActions.subscribe();
      }
    },
    onBlur: (state, { payload }) => {
      state.focused = false;

      // setTimeout(() => {
      //   window._WSConnection.disconnect();
      // }, 200);
    },
    setState: (state, { payload }) => {
      // if (shouldUpdateState(state.step, payload)) {
      state.step = payload;
      // }
    },
    setLang: (state, { payload }) => {
      state.lang = payload;
    },
    setSecondsLeft: (state, { payload }) => {
      state.secondsLeft = payload;
    },
    participate: (state, action) => {
      window._WSActions.subscribe();
    },
    emitTick(state, { payload }) {
      if (!state.connected && state.startedAt) {
        const nextStep = offlineState(state.step, state.startedAt);

        if (nextStep) {
          state.step = nextStep;
        }
      }
    },
    setNextWord: (state, { payload }) =>{
      state.currentWordIndex = state.currentWordIndex + 1;
    },
    setFinished: (state, { payload })=>{
      state.finised = true;
    },
    saveAttempt: (state, { payload })=>{
      state.attempts= payload
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  onMessage,
  onDisconnection,
  onConnection,
  setState,
  setLang,
  setSecondsLeft,
  participate,
  onFocus,
  onBlur,
  emitTick,
  setSession,
  createFakeSession,
  setProfilePicture,
  applyAnswer,
  setNextWord,
  setFinished,
  saveAttempt
} = stateMachine.actions;

export const actions = stateMachine.actions;

export default stateMachine.reducer;
