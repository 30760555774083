import { useEffect, useState } from "react";

const Page = ({ children, className }) => {
  const getCustomDimensions = () => {
    if (className.indexOf("Client") !== -1) {
      const height = window.getComputedStyle(
        document.querySelector("#full-height")
      ).height;
      return {
        height: height,
        "--height": height
      };
    } else {
      return {};
    }
  };

  const [customStyle, setCustomStyle] = useState(
    getCustomDimensions()
  );

  useEffect(() => {
    if (className.indexOf("Client") !== -1) {
      window.addEventListener("resize", () => {
        if (!window.resizeDebounce) {
          window.resizeDebounce = setTimeout(() => {
            setCustomStyle(getCustomDimensions());
            window.resizeDebounce = null;
          }, 500);
        }
      });
    }
  });

  return (
    <div className={`Page ${className}`} style={customStyle}>
      <div className="Page__content">{children}</div>
    </div>
  );
};

export default Page;
