import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setState } from "../../stateMachine";
import LoadingIcon from "../../../../ui/LoadingIcon";
import I18n from "../../../../ui/I18n";
import catalogue from "./avatars.json";
import Waves from "../../../../ui/Waves";

const AvatarSelection = () => {
  const dispatch = useDispatch();
  const [avatar, setAvatar] = useState(null);
  const [avatarSrc, setAvatarSrc] = useState(null);
  const [connecting, setConnecting] = useState(false);
  const [error, setError] = useState(null);
  const buttons = [
    {
      id: 1,
      title: "picture",
      isDefault: false
    },
    {
      id: 2,
      title: "avatar",
      isDefault: true
    }
  ];
  const defaultBtn = buttons.find((btn) => btn.isDefault === true);
  const [activeButtonId, setActiveButtonId] = useState(
    defaultBtn ? defaultBtn.id : null
  );

  const handleButtonClick = (e) => {
    setActiveButtonId(Number(e.target.value));
    dispatch(setState("signup_image"));
  };

  const handleAvatarClick = async (e) => {
    setAvatarSrc(e.target.src);
    setAvatar(e.target.name);
  };

  useEffect(() => {
    if (avatarSrc) {
      setTimeout(() => {
        window.tempAvatar = avatar;
        window.tempAvatarSrc = avatarSrc;
        dispatch(setState("show_avatar"));
      }, 100);
    }
  }, [avatar]);

  return (
    <div className="SignupImagePage__avatarSelection">
      <Waves />
      {error && (
        <div className="SignupImagePage__error">
          <I18n t={`client.signup.errors.${error}`} />
        </div>
      )}
      <div className="SignupImagePage__header">
        <span>
          <I18n t="client.signup.header.avatar" />
        </span>
      </div>
      <div className="SignupImagePage__avatarGrid">
        {catalogue.avatars.map((avtr) => (
          <img
            key={avtr.name}
            name={avtr.name}
            className="SignupImagePage__gridItem"
            src={avtr.url}
            onClick={handleAvatarClick}></img>
        ))}
      </div>
      {/* <div className="SignupImagePage__container">
        <div className="SignupImagePage__buttons">
          <button
            key={buttons[0].id}
            variant="dark mt-3 mr-3 "
            className={
              buttons[0].id === activeButtonId
                ? "choiceButton"
                : "is_active choiceButton choiceButton--transparent"
            }
            value={buttons[0].id}
            onClick={handleButtonClick}>
            <I18n t={`client.signup.button.${buttons[0].title}`} />
          </button>
          <button
            key={buttons[1].id}
            variant="dark mt-3 mr-3 "
            className={
              buttons[1].id === activeButtonId
                ? "choiceButton"
                : "is_active choiceButton choiceButton--transparent"
            }
            value={buttons[1].id}>
            <I18n t={`client.signup.button.${buttons[1].title}`} />
          </button>
        </div>
        <button
          className="button choiceButton--validate"
          style={{
            opacity: "0",
            marginBottom: "23px",
            marginTop: "23px"
          }}
          disabled={connecting}>
          {connecting ? (
            <LoadingIcon />
          ) : (
            <I18n t="client.signup.button.validate" />
          )}
        </button>
        <div className="SignupImagePage__footer"></div>
      </div> */}
    </div>
  );
};

export default AvatarSelection;
