import ProviderWS from "../../Provider";
import stateMachine, {
  initialState,
  onMessage,
  onConnection,
  onDisconnection
} from "./stateMachine";
import PageRedux from "../../ui/Page/PageRedux";

import StateRouter from "./StateRouter";

// window.updateUnlocked = false;

const Client = ({
  match: {
    params: { hash }
  }
}) => {
  const state = {
    ...initialState
    // step: 'home',
    // lang: "fr-CA"
  };

  return (
    <ProviderWS
      namespace="/texting_client"
      state={state}
      hash={hash}
      autoConnect={false}
      reducers={stateMachine}
      onMessage={onMessage}
      onConnection={onConnection}
      onDisconnection={onDisconnection}>
      <PageRedux className="Client" hash={hash}>
        <StateRouter />
      </PageRedux>
    </ProviderWS>
  );
};

export default Client;
