import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSession, setState } from "../../stateMachine";
import I18n from "../../../../ui/I18n";
import LoadingIcon from "../../../../ui/LoadingIcon";
import { signup } from "../../../../../configs/api";
import decoration_image from "../../../../../images/deco_up.png";

const InitialsForm = () => {
  const hash = useSelector((state) => state.stateMachine.hash);

  const dispatch = useDispatch();
  const [initial, setInitial] = useState("");
  const [bellCustomer, setBellCustomer] = useState("");
  const [error, setError] = useState(null);
  const [connecting, setConnecting] = useState(false);

  const handleInputChange = (e) => {
    var specialChars = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    if(!e.target.value.match(specialChars)){
      setError(null);
      setInitial(e.target.value)
    }else{
      e.preventDefault()
      setError("special_char");
    }
  };

  const handleSelectChange = (e)=>{
    setBellCustomer(e.target.value);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setConnecting(true);
    setError(null);

    signup(hash, initial, bellCustomer)
      .then((session) => {
        if (session?.error) {
          if(session.error === 'signup_ended'){
            dispatch(setState('sorry'));
          }else{
            setError(session?.error);
          }
        } else {
          dispatch(setSession({ ...session, hash }));
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setConnecting(false);
      });
  };

  return (
    <div className="SignupInitialsPage">
      <div className="SignupInitialsPage_top">
        <img
          className="SignupInitialsPage__decoration-image"
          src={decoration_image}
          alt=""
        />
      </div>
      <div className="SignupInitialsPage_bottom">
        <div className="SignupInitialsPage__text SignupInitialsPage__animation--downFade">
          <I18n t="client.signup.content" />
        </div>

        <form
          className="SignupInitialsPage__form SignupInitialsPage__animation--upFade"
          onSubmit={handleSubmit}>
          {error && (
            <div className="Signup__error">
              <I18n t={`client.signup.errors.${error}`} />
            </div>
          )}
          <input
            className="SignupInitialsPage__login-password"
            type="text"
            id="initial"
            name="initial"
            maxLength="12"
            value={initial}
            autoComplete="off"
            onChange={handleInputChange}
            required
          />
          <div className="SignupInitialsPage__text">Êtes-vous un client de Bell? </div>

          <div className="SignupInitialsPage__login-dropdown">
            <select  required onChange={handleSelectChange}>
              <option value="" selected disabled hidden>Choisissez ici</option>
              <option value="Oui">Oui</option>
              <option value="Non">Non</option>
              <option value="Je préfère ne pas répondre">Je préfère ne pas répondre</option>
            </select>
          </div>

          <button
            type="submit"
            className="button button--long"
            data-action="signup"
            disabled={connecting}>
            {connecting ? (
              <LoadingIcon />
            ) : (
              <I18n t="client.signup.button.validate" />
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default InitialsForm;
