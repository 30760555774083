import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Timer from ".././Timer";
import GameForm from "./Form";
import Waves from "../../../../ui/Waves";

import { setNextWord, setFinished, saveAttempt } from "../../stateMachine";
const Game = () => {
  const dispatch = useDispatch();

  const words = useSelector((state) => state.stateMachine.words);
  const score = useSelector((state) => state.stateMachine.score);

  const currentWordIndex = useSelector((state) => state.stateMachine.currentWordIndex);
  const finished = useSelector((state) => state.stateMachine.finished);
  const attempts = useSelector((state) => state.stateMachine.attempts);

  const word = words[currentWordIndex % words.length];

  const handleAttempt = (word, correct) => {
    dispatch(setNextWord());
    dispatch(saveAttempt([...attempts, { word, correct }]));
    
    if (currentWordIndex + 1 === words.length) {
      dispatch(setFinished());
    }
  };

  const renderAttempts = () => {
    return attempts.slice(-5).map(({ word, correct }) => {
      return (
        <div
          className={`attempt attempt--${correct ? "correct" : "incorrect"}`}
        >
          {word}
        </div>
      );
    });
  };

  return (
    <div className="GamePage__container">
      <Waves />
      <div className="GamePage__top">
        <div className="GamePage__header">{score || 0} pts</div>

        <div className="GamePage__timer">
          <Timer />
        </div>
      </div>
      <div className="GamePage__word">{word.word}</div>

      {!finished && <GameForm word={word} onAttempt={handleAttempt} />}

      {!finished && (
        <div className="GamePage__attempts">{renderAttempts()}</div>
      )}
    </div>
  );
};

export default Game;
