import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setProfilePicture, setState } from "../../stateMachine";
import { uploadProfilePicture } from "../../../../../configs/api";
import I18n from "../../../../ui/I18n";
import LoadingIcon from "../../../../ui/LoadingIcon";
import Waves from "../../../../ui/Waves";

const ShowAvatar = () => {
  const hash = useSelector((state) => state.stateMachine.hash);
  const sessionID = useSelector(
    (state) => state.stateMachine.sessionID
  );
  const dispatch = useDispatch();
  const [image, setImage] = useState(null);
  const [connecting, setConnecting] = useState(false);
  const [error, setError] = useState(null);
  let payload = null;

  const handleStartOver = () => {
    setImage(null);
    window.tempAvatar = null;
    dispatch(setState("signup_avatar"));
  };

  const makePayload = () => {
    if (window.tempAvatar) {
      return { profile: window.tempAvatar };
    } else {
      return null;
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setConnecting(true);
    payload = makePayload();
    if (payload) {
      uploadProfilePicture(hash, sessionID, payload)
        .then((session) => {
          dispatch(setProfilePicture(session));
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          setConnecting(false);
        });
    } else {
      setError("no_image_chosen");
    }
  };

  return (
    <div className="SignupImagePage__avatarSelection">
      <Waves />
      {error && (
        <div className="SignupImagePage__error">
          <I18n t={`client.signup.errors.${error}`} />
        </div>
      )}
      <div className="SignupImagePage__headerAvatar">
        <span>
          <I18n t="client.signup.header.avatar_preview" />
        </span>
      </div>
      <div className="SignupImagePage__headerAvatar_flex">
        <img
          className="SignupImagePage__imageAvatar"
          src={window.tempAvatarSrc}
          style={{ width: "90vw" }}></img>
      </div>

      <div className="SignupImagePage__imagecontainer">
        <div className="SignupImagePage__selfieButtons">
          <button
            className=" button choiceButton--validate"
            onClick={handleSubmit}
            disabled={connecting}>
            {connecting ? (
              <LoadingIcon />
            ) : (
              <I18n t="client.signup.button.validate" />
            )}
          </button>
          <button
            className="button choiceButton--startOver"
            onClick={handleStartOver}>
            <I18n t="client.signup.button.startover" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ShowAvatar;
