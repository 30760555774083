import I18n from "../../../ui/I18n";
import bottom_decoration from "../../../../images/sorry_dec_bottom.png";
import instructions_tv from "../../../../images/instructions_tv.jpg";
import instructions_texting from "../../../../images/instructions_texting.jpg";
import instructions_keyboard from "../../../../images/instructions_keyboard.jpg";
const Instructions = () => {
  return (
    <div className="InstructionsPage">
      <div className="InstructionsPage__title">
        <I18n t="client.instructions.title" />
      </div>
      <div className="InstructionsPage__content">
        <I18n t="client.instructions.content" />
      </div>
      <img
        className="InstructionsPage__image"
        src={instructions_texting}></img>
      <div className="InstructionsPage__content">
        <I18n t="client.instructions.description_one" />
      </div>
      <img
        className="InstructionsPage__image"
        src={instructions_keyboard}></img>
      <div className="InstructionsPage__content">
        <I18n t="client.instructions.description_two" />
      </div>
      <img
        className="InstructionsPage__image"
        src={instructions_tv}></img>
      <div className="InstructionsPage__title">
        <I18n t="client.instructions.footer" />
      </div>
      <img
        className="InstructionsPage__decorations"
        src={bottom_decoration}></img>
    </div>
  );
};

export default Instructions;
