import { useEffect } from "react";
import crown from "../../../../images/crown.svg";
import { useSelector, useDispatch } from "react-redux";
import confetti from "../../../ui/Confetti";
import I18n from "../../../ui/I18n";
import { setState } from "../stateMachine";
import Waves from "../../../ui/Waves";

const Final = () => {
  const winner = useSelector((state) => state.stateMachine.winner);
  const score = useSelector((state) => state.stateMachine.score);
  const profilePicture = useSelector(
    (state) => state.stateMachine.profilePicture
  );
  const dispatch = useDispatch();
  let messageKey = "loser";

  useEffect(() => {
    if (winner) {
      confetti();
    }
  }, []);

  if (winner) {
    messageKey = "winner";
  }

  setTimeout(() => {
    dispatch(setState("survey"));
  }, 10000);

  return (
    <div className="FinalPage__container">
      <Waves style={{ "z-index": "0" }} />
      <canvas
        className="FinalPage__canvas"
        id="confetti-canvas"></canvas>
      <div className="FinalPage__title">
        <I18n t="client.game.final.title" />
      </div>
      <div className="FinalPage__score">
        <I18n t="client.game.final.score" params={{ score }} />
      </div>
      <div className="FinalPage__avatar">
        {winner && (
          <img className="FinalPage__avatar-crown" src={crown} />
        )}
        <img
          className="FinalPage__avatar-image"
          src={profilePicture}
        />
      </div>
      <div className="FinalPage__player-message">
        <I18n t={`client.game.final.message.${messageKey}`} />
      </div>
    </div>
  );
};

export default Final;
