import { useEffect, useState } from "react";

const StepTransition = ({
  animation,
  children,
  onAnimationEnd,
  onTransitionEnd
}) => {
  const [animate, setAnimate] = useState("");
  useEffect(()=>{
    if(animation == 'none'){
      onTransitionEnd();
    }else{
      setTimeout(() => {
        setAnimate("animate");
      }, 500);
    }
  })

  const classNames = `animation animation__${animation} ${animate}`;

  const handleAnimationEnd = (e) => {
    if (e.target.classList.contains("current")) {
      onAnimationEnd && onAnimationEnd();

      setAnimate("static");
    }
  };

  const handleTransitionEnd = (e) => {
    if (e.target.classList.contains("current")) {
      onTransitionEnd && onTransitionEnd();

      setAnimate("static");
    }
  };

  return (
    <div
      className={classNames}
      onAnimationEnd={handleAnimationEnd}
      onTransitionEnd={handleTransitionEnd}>
      {children}
    </div>
  );
};

export default StepTransition;
